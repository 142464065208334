var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"chat-app common-left-right",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(_vm.chatsLoaded)?_c('v-card',{staticClass:"d-flex flex-row"},[_c('div',[_c('div',{staticClass:"px-3 border-bottom"},[_c('v-text-field',{staticClass:"mb-0 mt-0",attrs:{"placeholder":"Search contact"},model:{value:(_vm.handleSearchInput),callback:function ($$v) {_vm.handleSearchInput=$$v},expression:"handleSearchInput"}})],1),_c('v-list',{staticClass:"hightauto",attrs:{"nav":""}},_vm._l((_vm.filteredList),function(conversation,i){return _c('v-list-item',{key:i,class:_vm.isActive ? 'active' : 's',on:{"click":e => _vm.openMessages(conversation, e)}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('h4',[_vm._v(_vm._s(_vm.getConversationName(conversation)))])])],1)],1)}),1)],1),_c('div',{staticClass:"chat-right-part"},[(_vm.conversation)?[_c('div',{staticClass:"chat-topbar d-flex px-3 py-3 align-center"},[_c('div',[_c('v-app-bar-nav-icon',{staticClass:"d-block d-lg-none mr-2",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}})],1),_c('div',{staticClass:"user-about"},[_c('h6',[_vm._v(_vm._s(_vm.getConversationName(_vm.conversation)))])])]),_c('div',[_c('div',{ref:"messageDisplay",staticClass:"chat-room px-3 py-3",staticStyle:{"max-height":"calc(100vh - 400px)","overflow":"scroll"}},_vm._l((_vm.conversationMessage),function(message,index){return _c('div',{key:message.id,staticClass:"d-flex align-center mb-3",class:{ fromMe: _vm.isFromMe(message), messageItem: true },attrs:{"light":""}},[_c('div',{staticClass:"thumb"}),_c('div',{staticClass:"uk-flex uk-flex-column"},[(
                        index == 0 ||
                          (_vm.conversation.chat_messages[index - 1].user &&
                            _vm.conversation.chat_messages[index - 1].user.id !=
                              _vm.conversation.chat_messages[index].user.id)
                      )?_c('div',{staticClass:"user-name"},[_vm._v(" "+_vm._s(message.user ? message.user.firstName + " " + message.user.lastName : "-")+" ")]):_vm._e(),_c('div',{attrs:{"color":_vm.isFromMe(message) ? 'primary' : ''}},[_vm._v(" "+_vm._s(message.text)+" ")]),_c('div',{directives:[{name:"luxon",rawName:"v-luxon",value:({
                        value: message.created_at
                      }),expression:"{\n                        value: message.created_at\n                      }"}],staticClass:"message-date"})])])}),0)]),_c('div',{staticClass:"pa-3 border-top"},[_c('v-textarea',{attrs:{"name":"input-4-1","rows":"2","placeholder":"Type and hit Enter"},on:{"keydown":_vm.addMessage}})],1)]:[_c('div',{staticClass:"d-flex justify-center hightauto align-center"},[_c('h4',[_vm._v("Start conversation")])])]],2)]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }