<template>
  <v-container fluid class="chat-app common-left-right">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="d-flex flex-row" v-if="chatsLoaded">
          <div>
            <!-- {{filteredList}} -->
            <!---/Left chat list -->
            <!-- <v-navigation-drawer left v-model="drawer" class="flex-shrink-0"> -->
            <div class="px-3 border-bottom">
              <v-text-field
                placeholder="Search contact"
                class="mb-0 mt-0"
                v-model="handleSearchInput"
              ></v-text-field>
            </div>
            <v-list nav class="hightauto">
              <v-list-item
                v-for="(conversation, i) in filteredList"
                :key="i"
                @click="e => openMessages(conversation, e)"
                :class="isActive ? 'active' : 's'"
              >
                <!-- <v-avatar size="42" class="mr-3">
                    <img
                      :src="
                        require(`@/assets/images/users/${conversation.image}`)
                      "
                      :alt="conversation.image"
                    />
                  </v-avatar>-->
                <v-list-item-content>
                  <v-list-item-title>
                    <h4>{{ getConversationName(conversation) }}</h4>
                    <!-- <span class="caption">
                      {{
                        conversation.chat_messages &&
                        conversation.chat_messages.length > 0
                          ? conversation.chat_messages[
                              conversation.chat_messages.length - 1
                            ].text
                          : ""
                      }}
                    </span> -->
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <!-- </v-navigation-drawer> -->
          </div>
          <!---/Left chat list -->
          <!---right chat conversation -->
          <div class="chat-right-part">
            <template v-if="conversation">
              <!---conversation header-->
              <div class="chat-topbar d-flex px-3 py-3 align-center">
                <div>
                  <v-app-bar-nav-icon
                    @click.stop="drawer = !drawer"
                    class="d-block d-lg-none mr-2"
                  />
                </div>
                <!-- <v-avatar size="42" class="mr-3">
                  <img
                    :src="
                      require(`@/assets/images/users/${conversation.image}`)
                    "
                    width="37"
                  />
                </v-avatar>-->
                <div class="user-about">
                  <h6>{{ getConversationName(conversation) }}</h6>
                </div>
              </div>
              <!---conversation header-->
              <!---Chat Room-->
              <div>
                <div
                  class="chat-room px-3 py-3"
                  style="max-height: calc(100vh - 400px);overflow: scroll;"
                  ref="messageDisplay"
                >
                  <div
                    class="d-flex align-center mb-3"
                    light
                    v-for="(message, index) in conversationMessage"
                    :key="message.id"
                    :class="{ fromMe: isFromMe(message), messageItem: true }"
                  >
                    <div class="thumb">
                      <!-- <v-avatar size="37" class="mx-2" v-if="message.fromMe">
                        <img src="@/assets/images/users/6.jpg" alt="..." />
                      </v-avatar>
                      <v-avatar size="37" class="mx-2" v-else>
                        <img
                          :src="
                            require(`@/assets/images/users/${conversation.image}`)
                          "
                          alt="..."
                        />
                      </v-avatar>-->
                    </div>
                    <div class="uk-flex uk-flex-column">
                      <div
                        class="user-name"
                        v-if="
                          index == 0 ||
                            (conversation.chat_messages[index - 1].user &&
                              conversation.chat_messages[index - 1].user.id !=
                                conversation.chat_messages[index].user.id)
                        "
                      >
                        {{
                          message.user
                            ? message.user.firstName +
                              " " +
                              message.user.lastName
                            : "-"
                        }}
                      </div>
                      <div :color="isFromMe(message) ? 'primary' : ''">
                        {{ message.text }}
                      </div>
                      <div
                        class="message-date"
                        v-luxon="{
                          value: message.created_at
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!---Chat Room-->
              <div class="pa-3 border-top">
                <v-textarea
                  name="input-4-1"
                  rows="2"
                  placeholder="Type and hit Enter"
                  @keydown="addMessage"
                ></v-textarea>
              </div>
            </template>
            <template v-else>
              <div class="d-flex justify-center hightauto align-center">
                <h4>Start conversation</h4>
              </div>
            </template>
          </div>
          <!---right chat conversation -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
// import UsersList from "./userslist.js";

import { mapGetters } from "vuex";
export default {
  name: "Chat",
  components: {},
  data() {
    return {
      drawer: null,
      doNotClose: true,
      handleSearchInput: "",
      // UsersList: UsersList,
      chatUserActive: true,
      conversation: "",
      sendMessage: " ",
      isActive: false,
      windowWidth: window.innerWidth,
      //
      chatsCached: [],
      chatsLoaded: false
    };
  },
  apollo: {
    Chats: {
      prefetch: true,
      query: require("@/graphql/Chats.gql"),
      fetchPolicy: "cache-and-network",
      pollInterval: 5000,
      notifyOnNetworkStatusChange: true,
      update: data => data,
      result(data) {
        if (!data || !data.data || !data.data.chats) return; // || data.loading
        try {
          const { parse, stringify } = require("flatted/cjs");
          localStorage.setItem("chats", stringify(data.data.chats));
        } catch (err) {
          console.log("error with cache", err);
        }
        try {
          this.chatsCached = data.data.chats;
          // console.log(this.chatsCached);
          // console.log(this.$route.query);
          if (this.conversation) {
            for (let i = 0; i < this.chatsCached.length; i++) {
              if (this.conversation.id == this.chatsCached[i].id) {
                Object.assign(this.conversation, this.chatsCached[i]);
              }
            }
          }

          if (this.$route.query.id && !this.conversation) {
            for (let i = 0; i < this.chatsCached.length; i++) {
              if (this.chatsCached[i].id == this.$route.query.id) {
                // this.conversation = this.chatsCached[i];
                this.openMessages(this.chatsCached[i]);
                break;
              }
            }
          }
        } catch (err) {
          console.error(err);
        }

        this.chatsLoaded = true;

        // console.log('this. conversation', this.conversation);
      }
    }
  },
  watch: {
    "Chats.data"() {
      this.scrollToBottom();
    }
  },
  methods: {
    isFromMe(message) {
      return message && message.user && message.user.id == this.user.id;
    },
    getConversationName(conversation) {
      return conversation.users
        .filter(item => item.id != this.user.id)
        .map(item => item.firstName + " " + item.lastName)
        .join(", ");
    },
    selectedchat: function() {
      this.isActive = !this.isActive;
      // some code to filter users
    },
    scrollToBottom() {
      // console.log("scrollToBottom");
      this.$nextTick(function() {
        var container = this.$refs.messageDisplay;
        if (container) container.scrollTop = container.scrollHeight + 120;
      });
    },
    openMessages(conversation) {
      Vue.set(this, "conversation", conversation);
      this.scrollToBottom();
    },
    async addMessage(e) {
      if (e.key === "Enter" && e.target.value) {
        // const value = {
        //   text: e.target.value,
        //   fromMe: true,
        // };

        var newMessage = await this.strapi.createEntry("chatmessages", {
          text: e.target.value,
          chat: this.conversation.id,
          user: this.user.id
        });

        if (!this.conversation.chat_messages)
          this.conversation.chat_messages = [];

        this.conversation.chat_messages.push(newMessage);

        // Vue.set(
        //   this,
        //   "conversation",
        //   Object.assign({}, this.conversation, {
        //     messages: [...(this.conversation.messages || []), value],
        //   })
        // );

        e.target.value = "";
      }
    },

    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebarWidth();
    },
    setSidebarWidth() {
      if (this.windowWidth < 1170) {
        this.chatSidebarActive = this.doNotClose = false;
      } else {
        this.chatSidebarActive = this.doNotClose = true;
      }
    }
  },
  mounted() {
    try {
      const { parse, stringify } = require("flatted/cjs");
      let chatsCached = parse(localStorage.getItem("chats"));
      if (chatsCached) {
        this.chatsCached = chatsCached;
        if (this.conversation) {
          for (let i = 0; i < this.chatsCached.length; i++) {
            if (this.conversation.id == this.chatsCached[i].id) {
              Object.assign(this.conversation, this.chatsCached[i]);
            }
          }
        }

        if (this.$route.query.id && !this.conversation) {
          for (let i = 0; i < this.chatsCached.length; i++) {
            if (this.chatsCached[i].id == this.$route.query.id) {
              this.openMessages(this.chatsCached[i]);
              break;
            }
          }
        }
        this.chatsLoaded = true;
      }
    } catch (err) {
      console.error(err);
    }
  },
  computed: {
    conversationMessage() {
      return this.conversation ? this.conversation.chat_messages : [];
    },
    filteredList() {
      // console.log("this.chatsCached", this.chatsCached);
      if (!this.chatsCached) {
        return [];
      }
      // console.log("this.handleSearchInput", this.handleSearchInput);
      if (
        !this.handleSearchInput ||
        this.handleSearchInput.trim().length == 0
      ) {
        return this.chatsCached;
      }
      return this.chatsCached.filter(item => {
        return item.users
          .map(user => user.firstName + " " + user.lastName)
          .join(",")
          .toLowerCase()
          .includes(this.handleSearchInput.toLowerCase());
      });
    },
    ...mapGetters(["user", "strapi"])
  },
  created() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.setSidebarWidth();
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleWindowResize);
  }
};
</script>
<style lang="scss" scoped>
.user-name {
  // color: rgb(81, 166, 248);
  font-weight: bold;
  font-size: smaller;
  margin-bottom: 10px;
}
.message-date {
  margin-top: 5px;
  font-size: 10px;
  color: gray;
}

.chat-app {
  overflow: hidden;
  .v-navigation-drawer__border {
    width: 0px;
  }
  .chat-right-part {
    width: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    .chat-topbar {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  .chat-room {
    min-height: calc(100vh - 385px);
    .fromMe {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
    }
  }
  .hightauto {
    min-height: calc(100vh - 360px);
  }
}
</style>
